import React from "react";

import SimpleTable from "../components/SimpleTable";
import Layout from "../components/Layout";
import styled from "styled-components";
import { Typography } from "@equinor/eds-core-react";

import { appendBr } from "../utils";
import {
  elTableMapping,
  instTableMapping,
  telecomTableMapping,
  utilitiesTableMapping,
  craneTableMapping,
} from "../utils/tableMappings";

const TableScrollContainer = styled.div`
  overflow-x: auto;
  & > Table {
    min-width: ${(props) => props.minWidth + "px"};
  }
`;

const Table = ({ mapping, data, minWidth = 1000 }) => (
  <TableScrollContainer minWidth={minWidth}>
    <SimpleTable mapping={mapping} data={data} />
  </TableScrollContainer>
);

const ShowTable = ({ title, footer, table, mapping, minWidth }) => (
  <>
    <Typography variant="h3">{title}</Typography>
    {table.length > 0 ? (
      <>
        <Table mapping={mapping} data={table} minWidth={minWidth} />
        <br />
        {footer()}
      </>
    ) : (
      <Typography variant="body_short">No entries to show.</Typography>
    )}
  </>
);

const ShowIfData = ({ dataFn, predicateFn, renderWithData, renderEmpty }) => {
  const data = dataFn();

  if (predicateFn(data)) {
    return renderWithData(data);
  }

  return renderEmpty();
};

const SectionStyle = styled.div`
  margin-top: 4rem;
  page-break-after: always;
`;

const Section = ({ children }) => <SectionStyle>{children}</SectionStyle>;

const HintContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    padding: 12px;
    margin-left: 24px;
  }
`;

// Template component

const plantTemplate = ({ pageContext }) => {
  const { pageContent, plantNavList } = pageContext;

  return (
    <Layout plantList={plantNavList}>
      <Typography variant="h1">
        Interface hook-up data sheet for {pageContent.plant}
      </Typography>
      <Typography variant="h4">Norsok Standard Z-015</Typography>
      <Section>
        <Typography variant="h3">General information</Typography>
        <Typography variant="h4">{`Plant type: ${pageContent.plantType.name}`}</Typography>
      </Section>
      <Section>
        <ShowTable
          title="Electrical"
          footer={() => (
            <HintContainer>
              <Typography variant="body_short">
                *<br />
                Main - Main Power,
                <br />
                Emg - Emergency Power,
                <br />
                Ess - Essential Power,
                <br />
                UPS - UPS Power
              </Typography>
              <Typography variant="body_short">
                **
                <br />
                S = Solidly (direkte jordet),
                <br />
                I = Isolated (isolert)
                <br />R = Resistor (resistansjordet)
              </Typography>
            </HintContainer>
          )}
          mapping={elTableMapping}
          table={pageContent.tables.electrical}
          minWidth={1300}
        />
      </Section>
      <Section>
        <Typography variant="h3">Instrument</Typography>
        <Table
          mapping={instTableMapping}
          data={pageContent.tables.instrument}
          minWidth={1300}
        />
      </Section>
      <Section>
        <Typography variant="h3">Telecom</Typography>
        <Table
          mapping={telecomTableMapping}
          data={pageContent.tables.telecom}
          minWidth={1200}
        />
      </Section>
      <Section>
        <Typography variant="h3">Utilities</Typography>
        <Table
          mapping={utilitiesTableMapping}
          data={pageContent.tables.utilities}
          minWidth={1200}
        />
      </Section>
      <Section>
        <ShowTable
          title="Lifting capacity offshore cranes"
          footer={() => (
            <Typography variant="body_short">
              * Significant waveheight [max. load / max. radius]
            </Typography>
          )}
          mapping={craneTableMapping}
          table={pageContent.tables.craneLiftingCapabilities}
          minWidth={1000}
        />
      </Section>
      <Section>
        <ShowIfData
          dataFn={() => appendBr("comment")(pageContent)}
          predicateFn={(d) => d.length > 0 && d[0].length > 0}
          renderWithData={(d) => (
            <>
              <Typography variant="h3">Comments</Typography>
              <Typography variant="body_short">{d}</Typography>
            </>
          )}
          renderEmpty={() => null}
        />
      </Section>
      <Section>
        <ShowIfData
          dataFn={() => pageContent.generalComments}
          predicateFn={() => pageContent.generalComments.length > 0}
          renderWithData={(comment) => (
            <>
              <Typography variant="h3">Additional information</Typography>
              <div dangerouslySetInnerHTML={{ __html: comment }} />
            </>
          )}
          renderEmpty={() => null}
        />
      </Section>
      <Section>
        <ShowIfData
          dataFn={() => pageContent.attachmentAssets}
          predicateFn={() => pageContent.attachmentAssets.length > 0}
          renderWithData={(d) => (
            <>
              <Typography variant="h3">Related attachments</Typography>
              <ul>
                {d.map((file) => (
                  <a key={file[0]} href={file[1]}>
                    <li>{file[0]}</li>
                  </a>
                ))}
              </ul>
            </>
          )}
          renderEmpty={() => null}
        />
      </Section>
    </Layout>
  );
};

export default plantTemplate;
