import React from "react";
import styled from "styled-components";
import { Table } from "@equinor/eds-core-react";
import { tokens } from "@equinor/eds-tokens";
import { switchcase } from "../utils";

const { background__light } = tokens.colors.ui;
const { success__highlight } = tokens.colors.interactive;

const successHover = "#C3F3D2";

const { Body, Row, Cell, Head } = Table;

const addCursor = ({ onClick }) => (onClick ? "cursor: pointer;" : undefined);

const RestingRow = styled(Row)`
  &:hover {
    background-color: ${background__light.hex};
  }
  ${addCursor}
`;

const SelectedRow = styled(Row)`
  background-color: ${success__highlight.hex};
  &:hover {
    background-color: ${successHover};
  }
  ${addCursor}
`;

const rowTypeSelector = switchcase({
  selected: SelectedRow,
})(RestingRow);

const SimpleTable = ({ mapping = [], data = [], options = {} }) => {
  const headerComp = mapping.map(({ header, key }) => (
    <Cell as="th" key={key}>
      {typeof header === "function" ? header() : header}
    </Cell>
  ));

  const { rowTypePredicate, onRowClick } = options;

  const rowType = (row) => {
    const rowType = rowTypePredicate ? rowTypePredicate(row) : "na";

    return rowTypeSelector(rowType);
  };

  const dataRows = data.map((row, idx) => {
    const rowId = "r" + idx;

    const cells = mapping.map(({ key, renderCell }) => {
      if (typeof renderCell === "function") {
        return <Cell key={key}>{renderCell(row)}</Cell>;
      }

      return <Cell key={key}>{row[key]}</Cell>;
    });

    const RowToUse = rowType(row);
    const onClick = onRowClick
      ? (row) => (e) => onRowClick(e, row)
      : () => undefined;

    return (
      <RowToUse key={rowId} onClick={onClick(row)}>
        {cells}
      </RowToUse>
    );
  });

  return (
    <Table>
      <Head>
        <Row>{headerComp}</Row>
      </Head>
      <Body>{dataRows}</Body>
    </Table>
  );
};

export default SimpleTable;
