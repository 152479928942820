import { addBrRender } from "./index";

export const elTableMapping = addBrRender([
  {
    header: "Power *",
    key: "power",
  },
  {
    header: "Volt [V]",
    key: "volt",
  },
  {
    header: "Frequency [Hz]",
    key: "frequency",
  },
  {
    header: "Phase Current [A]",
    key: "phase_current",
  },
  {
    header: "Neutral Loaded [Yes/No]",
    key: "neutral_loaded",
  },
  {
    header: "System earth **",
    key: "system_earth",
  },
  {
    header: "Short circuit level min [kA]",
    key: "short_circuit_level_min",
  },
  {
    header: "Short circuit level max [kA]",
    key: "short_circuit_level_max",
  },
  {
    header: "Distribution protection fuse [A]",
    key: "distribution_protection_fuse",
  },
  {
    header: "Distribution protection earth fault [mA]",
    key: "distribution_protection_earth_fault",
  },
  {
    header: "Connection platform (Description / Type)",
    key: "connection_platform",
  },
  {
    header: "Connection temporary equipment (Description / Type)",
    key: "connection_temporary_equipment",
  },
  {
    header: "Area (Module Number / Room Number)",
    key: "area",
  },
]);

export const instTableMapping = addBrRender([
  {
    header: "Function",
    key: "function",
  },
  {
    header: "Signal type",
    key: "signal_type",
  },
  {
    header: "Connection platform (Description / Type)",
    key: "connection_platform",
  },
  {
    header: "Connection temporary equipment (Description / Type)",
    key: "connection_temporary_equipment",
  },
  {
    header: "Area (Module Number / Room Number)",
    key: "area",
  },
]);

export const telecomTableMapping = instTableMapping;

export const utilitiesTableMapping = addBrRender([
  {
    header: "Function",
    key: "function",
  },
  {
    header: "Pressure [bar]",
    key: "pressure",
  },
  {
    header: "Amount/Flow [max capacity]",
    key: "amount_flow",
  },
  {
    header: "Connection - type",
    key: "connection_type",
  },
  {
    header: "Connection - diameter",
    key: "connection_diameter",
  },
  {
    header: "Connection - material",
    key: "connection_material",
  },
  {
    header: "Area (Module Number / Room Number)",
    key: "area",
  },
]);

export const craneTableMapping = addBrRender([
  {
    header: "Crane",
    key: "crane",
  },
  {
    header: "1 meter *",
    key: "one_meter_significant",
  },
  {
    header: "2 meter *",
    key: "two_meter_significant",
  },
  {
    header: "3 meter *",
    key: "three_meter_significant",
  },
]);
