import React from 'react';

export const switchcase = (cases) => (defaultCase) => (key) =>
  cases.hasOwnProperty(key) ? cases[key] : defaultCase;

const intersperse = (arr, fillFn) =>
  arr.flatMap((e, i) => [fillFn(i), e]).slice(1);

const nlSplit = (str) => (str ? str.split("\n") : [str]);

export const appendBr = (col) => (e) =>
  intersperse(nlSplit(e[col]), (i) => <br key={i} />);

export const addBrRender = (mappingTable) =>
  mappingTable.map((e) => ({ ...e, renderCell: appendBr(e.key) }));
